import UploadComponent from '@scioservices/upload-component';
import React, { useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import TypeSelector from './components/TypeSelector';
import DescriptionBox from './components/DescriptionBox';
import './styles.css';

const getName = (s3Name) => {
	const nameParts = s3Name.split('/')[1].split('_');
	nameParts.splice(0, 1);
	return nameParts.join('_');
};

const MetadataDocuments = ({ token, files, setFiles, deletedDocs, setDeletedDocs }) => {
	const [visible, setVisible] = useState();
	const [completedUploads, setCompletedUploads] = useState([]);
    
	useEffect(() => {
		if (completedUploads.length > 0) {
			const newFiles = [...files];
			const additional = completedUploads.map((upload) => {
				return { key: upload, filename: getName(upload), document_type: '', description: '' };
			});
			newFiles.push(...additional);
			setFiles(newFiles);
			setCompletedUploads([]);
		}
	}, [completedUploads]);

	const typeBody = (data) => {
		return <TypeSelector body={data} files={files} setFiles={setFiles} />;
	};

	const descriptionBody = (data) => {
		return <DescriptionBox body={data} files={files} setFiles={setFiles} />;
	};

	const deleteBody = (data) => {
		return (
			<Button
				icon="fa-regular fa-x"
				severity="danger"
				onClick={() => {
					const index = files.findIndex((file) => file.key === data.key);

					const newFiles = [...files];
					newFiles.splice(index, 1);
					setFiles(newFiles);

					const file = files[index].id;
					if (file) {
						const newDeleted = [...deletedDocs];
						newDeleted.push(file);
						setDeletedDocs(newDeleted);
					}
				}}
			/>
		);
	};

	return (
		<>
			<Button label="Upload Documents" onClick={() => setVisible(true)} /> 
			<DataTable value={files}>
				<Column field="filename" header="File Name" />
				<Column header="Type" body={typeBody} />
				<Column header="Description" body={descriptionBody} />
				<Column body={deleteBody} />
			</DataTable>
			<Dialog visible={visible} onHide={() => { if (!visible) return; setVisible(false); }} className="uploader-dialog">
				<UploadComponent
					completedUploads={completedUploads}
					setCompletedUploads={setCompletedUploads}
					devUrl={process.env.REACT_APP_BACKEND_URL}
					accessToken={token}
					uppyType="dashboard"
					note="Upload your data in tabular format. Current supported files are csv"
				/>
			</Dialog>
		</>
	);
};

export default MetadataDocuments;
