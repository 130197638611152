import { useQuery, useMutation } from '@tanstack/react-query';
import { httpUnintercepted } from '../../services/httpService';

const getDatapoolDatafiles = async (userId, datapoolId) => {
	const result = await httpUnintercepted.get(`user/${userId}/datapools/${datapoolId}/datafiles/all/get`);
	return result.data;
};
export const useGetDatapoolDatafiles = (userId, datapoolId) => useQuery({
	staleTime: Infinity,
	queryKey: ['getDatapoolDatafiles', datapoolId],
	queryFn: () =>
		getDatapoolDatafiles(userId, datapoolId),
});

const getDatapoolDatafilesInverse = async (userId, datapoolId) => {
	const result = await httpUnintercepted.get(`user/${userId}/datapools/${datapoolId}/datafiles/all/get-inverse`);
	return result.data;
};

export const useGetDatapoolDatafilesInverse = (userId, datapoolId, state) => useQuery({
	staleTime: Infinity,
	enabled: !!state,
	queryKey: ['getDatapoolDatafilesInverse', datapoolId],
	queryFn: () => getDatapoolDatafilesInverse(userId, datapoolId),
});

const deleteDatapool = async (userId, datapoolId) => {
	const result = await httpUnintercepted.delete(`user/${userId}/datapools/${datapoolId}/delete`);
	return result.data;
};
export const useDeleteDatapool = (userId, navigate, queryClient, setDeleteDatapoolLoading) => useMutation({
	mutationFn: (datapoolId) => deleteDatapool(userId, datapoolId),
	onSuccess: () => {
		queryClient.invalidateQueries('getPublicDatapools');
		queryClient.invalidateQueries('getUserDatapools');
		queryClient.invalidateQueries('getPinnedDatapools');
		queryClient.invalidateQueries('getDatapoolById');
		queryClient.invalidateQueries('getDatafileDatapools');
		navigate('../Home');
	},
	onSettled: () => {
		setDeleteDatapoolLoading(false);
	},
});

const editDatapoolMetadata = async (userId, datapoolId, description, citation, license, embargo_date, documents, deletedDocs) => {
	const result = await httpUnintercepted.post(`user/${userId}/datapools/${datapoolId}/edit/metadata`, {
		description,
		citation,
		license,
		embargo_date,
		documents,
		deleted_documents: deletedDocs,
	});
	return result.data;
};

const addDatapoolMetadataAndPublish = async (userId, datapoolId, description, citation, license, embargo_date, documents) => {
	const result = await httpUnintercepted.post(`user/${userId}/datapools/${datapoolId}/publish`, {
		description,
		citation,
		license,
		embargo_date,
		documents,
	});
	return result.data;
};

export const useEditMetadata = (navigate, location, queryClient, setLoading) => useMutation({
	mutationFn: ({ userId, datapoolId, descrition, citation, license, embargo_date, status, documents, deletedDocs }) => {
		if (status === 'public') {
			return editDatapoolMetadata(userId, datapoolId, descrition, citation, license, embargo_date, documents, deletedDocs);
		} 
		return addDatapoolMetadataAndPublish(userId, datapoolId, descrition, citation, license, embargo_date, documents);
	},
	onSuccess: () => {
		queryClient.invalidateQueries('getPublicDatapools');
		queryClient.invalidateQueries('getUserDatapools');
		queryClient.invalidateQueries('getPinnedDatapools');
		queryClient.invalidateQueries('getDatapoolById');
		queryClient.invalidateQueries('getDatafileDatapools');
		navigate('/Home', { state: { ...location.state } });
	},
	onSettled: () => {
		setLoading(false);
	},
});

const generateCodebook = async (userId, datapoolId, datafileKey, version, s3Location, selectedSheet) => {
	const data = {
		datapoolId,
		dataFile: datafileKey,
		version,
		destinationKey: s3Location,
		index: selectedSheet,
	};

	const result = await httpUnintercepted.post(`user/${userId}/datapools/${datapoolId}/versions/codebook/template/get`, data);
	return result.data;
};

export const useGenerateCodebook = (userId, datapoolId, key, targetVersion, s3Location, generate, setLoading, setTemplateUrl, setActiveStep, setGenerate, selectedSheet) => useQuery({
	staleTime: Infinity,
	enabled: generate,
	queryKey: ['generateVersion', datapoolId],
	queryFn: () =>
		generateCodebook(userId, datapoolId, key, targetVersion, s3Location, selectedSheet)
			.then((res) => {
				setLoading(false);
				setTemplateUrl(res);
				setActiveStep(1);
				setGenerate(false);
				return res;
			}),
});

const getDatafileSheets = async (userId, dfId) => {
	const result = await httpUnintercepted.get(`user/${userId}/datafiles/${dfId}/sheets/get`);
	return result.data;
};

export const useGetDatafileSheets = (user, datafileId) => useQuery({
	gcTime: 0,
	enabled: false,
	queryKey: ['getDatafileSheets', datafileId],
	queryFn: () =>
		getDatafileSheets(user.sub, datafileId),
});
const validateCodebook = async (userId, datapoolId, codebookKey) => {
	const data = {
		codebook: codebookKey,
	};
	const result = await httpUnintercepted.post(`user/${userId}/datapools/${datapoolId}/versions/codebook/validate`, data);
	return result.data;
};

export const useValidateCodebook = (userId, datapoolId, codebook, setValidationStatus, setActiveStep, validate, setValidate) => useQuery({
	gcTime: 0,
	enabled: false,
	queryKey: ['validateCodebook', datapoolId],
	queryFn: () =>
		validateCodebook(userId, datapoolId, codebook)
			.then((res) => {
				setValidationStatus(res);
				return res;
			})
			.finally(() => {
				setValidate(false);
				setActiveStep(2);
			}),
});

const generateVersion = async (userId, datapoolId, version, datapool, selectedFile, codebook, codebookTemplate, selectedSheet) => {
	const data = {
		version: version || 1,
		codebook,
		codebookTemplate,
		dataFileKey: selectedFile.key,
		dataFileId: selectedFile.datafile_id,
		alias: datapool.alias,
		index: selectedSheet,
	};
	const result = await httpUnintercepted.post(`user/${userId}/datapools/${datapoolId}/versions/generate`, data);
	return result.data;
};

export const useGenerateVersion = (userId, datapoolId, version, datapool, setLoading, navigate) => useMutation({
	mutationFn: ({ selectedFile, codebook, key, selectedSheet }) =>
		generateVersion(userId, datapoolId, version, datapool, selectedFile, codebook, key, selectedSheet),
	onSuccess: () => {
		navigate('../Home');
	},
	onSettled: () => {
		setLoading(false);
	},
});

const updateUserRole = async (userId, datapoolId, datapoolUserId, roleId) => {
	const result = await httpUnintercepted.post(`user/${userId}/datapools/${datapoolId}/users/${datapoolUserId}/role/${roleId}/update`);
	return result.data;
};

export const useUpdateUserRole = (userId, datapoolId, setLoading, setDialogVisibility, setSelectedRole, queryClient) => useMutation({
	mutationFn: ({ identity_provider_id, selectedRole }) =>
		updateUserRole(userId, datapoolId, identity_provider_id, selectedRole.id),
	onSuccess: () => {
		queryClient.invalidateQueries('getDatapoolUsers');
		queryClient.invalidateQueries('getUsersNotInDatapool');
	},
	onSettled: () => {
		setLoading(false);
		setDialogVisibility(false);
		setSelectedRole({});
	},
});

const getDatapoolUsers = async (userId, datapoolId) => {
	const result = await httpUnintercepted.get(`user/${userId}/datapools/${datapoolId}/users/all/get`);
	return result.data;
};

export const useGetDatapoolUsers = (userId, datapoolId) => useQuery({
	staleTime: Infinity,
	queryKey: ['getDatapoolUsers', datapoolId],
	queryFn: () =>
		getDatapoolUsers(userId, datapoolId),
});

const getDatapoolUsersInverse = async (userId, datapoolId) => {
	const result = await httpUnintercepted.get(`user/${userId}/datapools/${datapoolId}/users/all/get-inverse`);
	return result.data;
};

export const useGetDatapoolUsersInverse = (userId, datapoolId) => useQuery({
	staleTime: Infinity,
	queryKey: ['getUsersNotInDatapool', datapoolId],
	queryFn: () =>
		getDatapoolUsersInverse(userId, datapoolId),
});

const addUsersToDatapool = async (userId, datapoolId, users) => {
	const result = await httpUnintercepted.post(`user/${userId}/datapools/${datapoolId}/users/add`, { users });
	return result.data;
};

export const useAddUsersToDatapool = (userId, datapoolId, setSelectedUsers, queryClient, setAddUserLoading) => useMutation({
	mutationFn: (selectedUsers) =>
		addUsersToDatapool(userId, datapoolId, selectedUsers),
	onSuccess: () => {
		queryClient.invalidateQueries('getDatapoolUsers');
		queryClient.invalidateQueries('getUsersNotInDatapool');
	},
	onSettled: () => {
		setAddUserLoading(false);
		setSelectedUsers([]);
	},
});

const removeUserFromDatapool = async (userId, datapoolId, datapoolUserId) => {
	const result = await httpUnintercepted.delete(`user/${userId}/datapools/${datapoolId}/users/${datapoolUserId}/delete`);
	return result.data;
};

export const useRemoveUserFromDatapool = (userId, datapoolId, queryClient, setRemoveUserLoading) => useMutation({
	mutationFn: (data) => removeUserFromDatapool(userId, datapoolId, data.identity_provider_id, data.role_name),
	onSuccess: () => {
		queryClient.invalidateQueries('getDatapoolUsers');
		queryClient.invalidateQueries('getUsersNotInDatapool');
	},
	onSettled: () => {
		setRemoveUserLoading(false);
	},
});
