import { Dialog } from 'primereact/dialog';
import React, { useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useQueryClient } from '@tanstack/react-query';
import { useCreateDatapool } from '../../../../queryHooks';

const NewDataPoolDialog = ({ dialogStatus, setDialogStatus, user }) => {
	const [name, setName] = useState('');
	const [loading, setLoading] = useState(false);

	const queryClient = useQueryClient();
	const isFilledIn = () => {
		return name;
		// return name && tags.length > 0 && projects.length > 0;
	};

	const createDatapool = useCreateDatapool(user, setDialogStatus, setLoading, queryClient);

	const handleCreate = () => {
		setLoading(true);
        
		const data = {
			name,
			userId: user.sub,
		};

		createDatapool.mutateAsync(data).then(() => {
			handleCancel();
		}).catch((e) => {
			console.log(e);
		});
	};

	const handleCancel = () => {
		setDialogStatus(false);
		setTimeout(() => {
			setName('');
		}, [300]);
	};
    
	return (
		<Dialog header="New Datapool" visible={dialogStatus} style={{ width: '25vw' }} onHide={handleCancel} contentStyle={{ display: 'flex', flexDirection: 'column', gap: '20px', padding: '20px 20px' }}>
			<div className="flex flex-column gap-2">
				<label htmlFor="username">Name</label>
				<InputText value={name} onChange={(e) => setName(e.target.value)} style={{ width: '100%' }} placeholder="Enter Datapool name" />
			</div>
			<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
				<Button label="Create" onClick={handleCreate} disabled={loading || !isFilledIn()} loading={loading} />
			</div>
		</Dialog>
	);
};

export default NewDataPoolDialog;
