import React, { useState } from 'react';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { useLocation, useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { Calendar } from 'primereact/calendar';
import DatapoolHeader from '../../../../../../components/DatapoolHeader';
import { LicenseWizardDialog, MetadataDocuments } from './components';
import './styles.css';
import { useEditMetadata } from '../../../../queryHooks';

const EditMetadata = ({ token }) => {
	const location = useLocation();

	const embargoDate = location?.state?.datapool?.embargo_date;

	const navigate = useNavigate();
	const [descrition, setDescription] = useState(location?.state?.datapool?.description || '');
	const [citation, setCitation] = useState(location?.state?.datapool?.citation || '');
	const [license, setLicense] = useState(location?.state?.datapool?.license || '');
	const [files, setFiles] = useState(location?.state?.datapool?.documents || []);
	const [deletedDocs, setDeletedDocs] = useState([]);
	const [licenseWizardDialog, setLicenseWizardDialog] = useState(false);
	const [loading, setLoading] = useState(false);
	const [date, setDate] = useState(embargoDate ? new Date(Date.parse(embargoDate)) : null);

	const licenses = ['CC0', 'CC BY', 'CC BY-SA', 'CC BY-ND', 'CC BY-NC', 'CC BY-NC-SA', 'CC BY-NC-ND'];

	const queryClient = useQueryClient();

	const editMetadata = useEditMetadata(navigate, location, queryClient, setLoading);

	const handleClick = () => {
		setLoading(true);
		editMetadata.mutate(
			{
				userId: location.state.datapool.identity_provider_id,
				datapoolId: location.state.datapool.mongo_id,
				descrition,
				citation,
				license,
				embargo_date: date ? `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}` : null,
				status: location.state.datapool.status,
				documents: files,
				deletedDocs }
		);
	};

	return (
		<div className="edit-metadata">
			<DatapoolHeader datapool={location.state.datapool} version={location.state.version} />
			<div className="metadata">
				<div className="">
					<div className="input">
						<p><span style={{ color: 'red' }}>*</span> Description</p>
						<InputTextarea value={descrition} onChange={(e) => setDescription(e.target.value)} />
					</div>
					<div className="input">
						<p><span style={{ color: 'red' }}>*</span> How to cite</p>
						<InputTextarea value={citation} onChange={(e) => setCitation(e.target.value)} />
					</div>
					<div className="input">
						<p><span style={{ color: 'red' }}>*</span> License</p>
						<Dropdown value={license} options={licenses} onChange={(e) => setLicense(e.value)} />
						<Button className="license-wizard-button" label="License Wizard" onClick={() => setLicenseWizardDialog(true)} />
					</div>
					<div className="input">
						<p>Embargo Date</p>
						<Calendar value={date} onChange={(e) => setDate(e.value)} minDate={new Date()} dateFormat="dd/mm/yy" />
					</div>
					<div>
						<p>Accompanying Documents</p>
						<MetadataDocuments token={token} files={files} setFiles={setFiles} deletedDocs={deletedDocs} setDeletedDocs={setDeletedDocs} />
					</div>

				</div>
				<LicenseWizardDialog
					setDialogOpen={setLicenseWizardDialog}
					dialogOpen={licenseWizardDialog}
					setLicense={setLicense}
				/>
			</div>
			<Button className="publish-save" label={location.state.datapool.status === 'public' ? 'Save' : 'Publish'} onClick={() => handleClick()} loading={loading} />
		</div>
	);
};

export default EditMetadata;
