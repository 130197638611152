import React, { useState } from 'react';
import { Button } from 'primereact/button';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import './styles.css';
import { confirmDialog, ConfirmDialog } from 'primereact/confirmdialog';
import { useGenerateVersion } from '../../../../../../queryHooks';

const GenerateDP = ({ location, codebook, validationStatus, selectedFile, templateUrl, selectedSheet, setActiveStep, setCodebook }) => {
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();

	const queryClient = useQueryClient();

	const generateVersion = useGenerateVersion(location.state.userId, location.state.datapoolId, location.state.version, location.state.datapool, setLoading, navigate, queryClient);

	const handleGenerateVersion = () => {
		setLoading(true);
		generateVersion.mutate({ selectedFile, codebook, key: templateUrl.key, selectedSheet: selectedSheet?.name ? selectedSheet.index : -1 });
	};

	const confirmation = () => {
		confirmDialog({
			message: 'Your codebook contains PII data. Are you sure you want to proceed?',
			header: 'Confirmation',
			icon: 'pi pi-exclamation-triangle',
			defaultFocus: 'accept',
			accept: handleGenerateVersion, 
			reject: () => { console.log('reject'); navigate('/Home'); },
		});
	};

	const reviseCodebook = () => {
		setCodebook('');
		setActiveStep(1);
	};

	const renderContent = () => {
		if (validationStatus.validated && validationStatus.hasPII) {
			return (
				<>
					<div style={{ display: 'flex', flexDirection: 'row', gap: '10px', alignItems: 'end' }}>
						<i className="fa-regular fa-circle-exclamation" style={{ color: 'orange', fontSize: '24px' }} />
						<p>Codebook validated!</p>
					</div>
					<p>Your codebook contains PII data in the following columns: {validationStatus.piiColumns.join(', ')}.</p>
					<Button className="bottom-button" label="Generate Datapool Version" onClick={confirmation} loading={loading} />
					<Button className="bottom-button" label="Revise Codebook" onClick={() => reviseCodebook()} loading={loading} />
					<ConfirmDialog />
				</>
			);
		}
		if (validationStatus.validated) {
			return (
				<>
					<div style={{ display: 'flex', flexDirection: 'row', gap: '10px', alignItems: 'end' }}>
						<i className="fa-regular fa-circle-check" style={{ color: 'green', fontSize: '24px' }} />
						<p>Codebook validated!</p>
					</div>
					<Button className="bottom-button" label="Generate Datapool Version" onClick={handleGenerateVersion} loading={loading} />
				</>
			);
		}
		return (
			<>
				<div style={{ display: 'flex', flexDirection: 'row', gap: '10px', alignItems: 'end' }}>
					<i className="fa-regular fa-circle-x" style={{ color: 'red', fontSize: '24px' }} />
					<p>Validation issues.</p>
				</div>
				<Button label="Correct Codebook" icon="fa-solid fa-arrow-left" onClick={handleGenerateVersion} loading={loading} />
			</>
		);
	};

	return (
		renderContent()
	);
};

export default GenerateDP;
