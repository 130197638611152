import { useQuery, useMutation } from '@tanstack/react-query';
import { http, httpUnintercepted } from '../../services/httpService';

const getPublicDatapools = async () => {
	const result = await httpUnintercepted.get(`/datapools/public/all/get`);
	return result.data;
};

export const useGetPublicDatapools = (activeIndex) => useQuery({
	staleTime: Infinity,
	enabled: activeIndex !== 2,
	queryKey: ['getPublicDatapools'],
	queryFn: () => getPublicDatapools(),
});

const getPrivateDatapools = async (userId) => {
	const result = await httpUnintercepted.get(`user/${userId}/datapools/all/get`);
	return result.data;
};

export const useGetPrivateDatapools = (activeIndex, userId) => useQuery({
	staleTime: Infinity,
	enabled: activeIndex === 2,
	queryKey: ['getUserDatapools'],
	queryFn: () => getPrivateDatapools(userId),
});

const getUserPinnedDatapools = async (userId) => {
	const result = await httpUnintercepted.get(`user/${userId}/datapools/pinned/get`);
	return result.data;
};

export const useGetPinnedDatapoolIds = (activeIndex, isAuthenticated, user) => useQuery({
	staleTime: Infinity,
	enabled: isAuthenticated && ((activeIndex === 0) || (activeIndex === 1)),
	queryKey: ['getPinnedDatapools'],
	queryFn: () =>
		getUserPinnedDatapools(user.sub)
			.then((res) => {
				return res;
			}),
});

const createDatapool = async (userId, metadata) => {
	const result = await httpUnintercepted.post(`user/${userId}/datapools/create`, metadata);
	return result.data;
};

export const useCreateDatapool = (user, setDialogStatus, setLoading, queryClient) => useMutation({
	mutationFn: (metadata) => createDatapool(user.sub, metadata),
	onSuccess: () => {
		queryClient.invalidateQueries('getPublicDatapools');
		queryClient.invalidateQueries('getUserDatapools');
		queryClient.invalidateQueries('getPinnedDatapools');
		queryClient.invalidateQueries('getDatapoolById');
		queryClient.invalidateQueries('getDatafileDatapools');
	},
	onSettled: () => {
		setLoading(false);
		setDialogStatus(false);
	},
});

const unpinDatapool = async (userId, datapoolId) => {
	const result = await httpUnintercepted.post(`user/${userId}/datapools/${datapoolId}/unpin`);
	return result.data;
};

const pinDatapool = async (userId, datapoolId) => {
	const result = await httpUnintercepted.post(`user/${userId}/datapools/${datapoolId}/pin`);
	return result.data;
};
export const useChangePinStatus = (userId, itemData, pinnedDatapools, queryClient) => useMutation({
	mutationFn: (keyword) => {
		if (keyword === 'unpin') {
			return unpinDatapool(userId, itemData.datapool_id);
		} 
		return pinDatapool(userId, itemData.datapool_id);
	},
	onSuccess: () => {
		queryClient.invalidateQueries('getPinnedDatapools');
	},
});

const getDataXDatapoolById = async (id, publicStatus, userId) => {
	if (publicStatus) {
		const result = await http.get(`/datapools/public/${id}/get`);
		return result.data;
	}
	const result = await http.get(`user/${userId}/datapools/${id}/get`);
	return result.data;
};

export const useGetDataXDatapoolById = (selectedDatapool, userId) => useQuery({
	staleTime: Infinity,
	enabled: !!selectedDatapool.alias,
	queryKey: ['getDatapoolById', selectedDatapool.alias],
	queryFn: () =>
		getDataXDatapoolById(selectedDatapool.mongo_id, selectedDatapool.status === 'public', userId),
});
