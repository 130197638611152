import React, { useEffect, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { useAuth0 } from '@auth0/auth0-react';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { useGenerateCodebook, useGetDatafileSheets, useGetDatapoolDatafilesInverse } from '../../../../../../queryHooks';
import { Loading } from '../../../../../../../../components';

const SelectDataFile = ({ setSelectedSheet, selectedSheet, location, setActiveStep, setTemplateUrl, targetVersion, selectedFile, setSelectedFile }) => {
	const { user } = useAuth0();
	
	const [loading, setLoading] = useState(false);
	const [generate, setGenerate] = useState(false);
	const [selectSheetsDialog, setSelectSheetsDialog] = useState(false);

	const datafiles = useGetDatapoolDatafilesInverse(location.state.userId, location.state.datapoolId, location.state);

	const s3Location = `codebooks/cb_template_${location.state.datapool.alias}_${1}.xlsx`;
	const generateCodebook = useGenerateCodebook(location.state.userId, location.state.datapoolId, selectedFile?.key, targetVersion, s3Location, generate, setLoading, setTemplateUrl, setActiveStep, setGenerate, selectedSheet?.name ? selectedSheet.index : -1);
	const presignedUrl = useGetDatafileSheets(user, selectedFile?.datafile_id);

	const handleGenerate = async () => {
		const sheets = await presignedUrl.refetch();
		if (sheets.data.csv) {
			setGenerate(true);
		} else {
			setSelectSheetsDialog(true);
		}
	};

	useEffect(() => {
		setSelectedSheet(null);
	}, [selectedFile]);

	return (
		<>
			<div className="users-table-table-container">
				<p className="title">List of Datafiles not associated with current Datapool</p>
				<DataTable
					value={datafiles.data}
					showGridlines
					stripedRows
					selectionMode="single"
					selection={selectedFile}
					onSelectionChange={(e) => setSelectedFile(e.value)}
					metaKeySelection={false}
					paginator
					rows={5}
					rowsPerPageOptions={[5, 10, 25, 50]}
					loading={datafiles.isLoading}
					paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
					currentPageReportTemplate="{first} - {last} of {totalRecords}"
				>
					<Column selectionMode="single" headerStyle={{ width: '3rem' }} />
					<Column field="filename" header="File Name" />
				</DataTable>
			</div>
			<Button className="generate-template" label="Generate Codebook Template" icon="fa-solid fa-arrow-right" iconPos="right" onClick={handleGenerate} disabled={!selectedFile} loading={presignedUrl.isLoading} />
			<Dialog header="Header" visible={selectSheetsDialog} style={{ width: '50vw' }} onHide={() => { if (!selectSheetsDialog) return; setSelectSheetsDialog(false); }}>
				<Loading loading={presignedUrl.isLoading}>
					<div className="flex flex-col">
						<Dropdown
							value={selectedSheet}
							onChange={(e) => setSelectedSheet(e.value)}
							options={presignedUrl?.data?.sheets ? presignedUrl?.data?.sheets : []}
							optionLabel="name"
						/>
						<Button className="generate-template" label="Generate Codebook Template" icon="fa-solid fa-arrow-right" iconPos="right" onClick={() => setGenerate(true)} disabled={!selectedFile} loading={generateCodebook.isLoading} />
					</div>
				</Loading>
			</Dialog>
		</>

	);
};

export default SelectDataFile;
