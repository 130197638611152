import React, { useState, useEffect } from 'react';
import './styles.css';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import NameDialog from './components/NameDialog';
import { renderLicences } from '../../utils/renderLicense';
import { useGetCurrentCodebookPresigned, useLockDatapool, useUnlockDatapool } from './queryHooks';

const DatapoolHeader = ({ datapool, headerState, version, openCreateApiDialog }) => {
	const queryClient = useQueryClient();
	
	const navigate = useNavigate();
	const [dialogVisibility, setDialogVisibility] = useState(false);
	const [download, setDownload] = useState(0);
	const [lockLoading, setLockLoading] = useState(false);

	const downloadCodebook = useGetCurrentCodebookPresigned(datapool, download, setDownload);

	const lockDatapool = useLockDatapool(queryClient, setLockLoading);

	const unlockDatapool = useUnlockDatapool(queryClient, setLockLoading);

	useEffect(
		() => {
			if (downloadCodebook.isSuccess) {
				window.open(downloadCodebook.data.url, '_blank');
			}
		}, [download, downloadCodebook.data]
	);
	const handleCodebookDownload = () => {
		setDownload((prev) => prev + 1);
	};

	const handleLock = () => {
		setLockLoading(true);
		lockDatapool.mutate(datapool);
	};

	const handleUnlock = () => {
		setLockLoading(true);
		unlockDatapool.mutate(datapool);
	};

	return (
		<>
			<div className="header-card" style={{ display: 'flex', flexDirection: 'row' }}>
				<div className="header-item left-item">
					<div className="content">
						<p className="id">{datapool.mongo_id || 'Datapool ID'}</p>
						<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
							{/* {datapool.status === 'public' ? <p className="name">{datapool.name || ''}</p> : <p className="name">{datapool.datapool_name || ''}</p>} */}
							<p className="name">{datapool.datapool_name || datapool.name || ''}</p>
							{headerState?.editName ? <Button text icon="fa-solid fa-pen-to-square" onClick={() => setDialogVisibility(true)} /> : null}
						</div>
						<p className="records">{datapool.records ? `[${datapool.records}]` : '[0]'}</p>
						<p className="description">{datapool.description || ''}</p>
						<div className="licenses">
							{renderLicences(datapool.license)}
						</div>
						{datapool.citation ? <p className="citation"><span>How to cite: </span>{datapool.citation}</p> : null}
						{headerState?.codebook ? <Button icon="fa-solid fa-download" label="Codebook" style={{ width: '150px', marginTop: 'auto' }} onClick={handleCodebookDownload} loading={downloadCodebook.isLoading} /> : null}
					</div>
				</div>
				<div className="header-item" style={{ marginLeft: 'auto' }}>
					<div className="content" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
						{headerState?.api
							? (
								<Button
									icon="fa-solid fa-webhook"
									label="Create API"
									style={{ marginLeft: 'auto' }}
									onClick={() => openCreateApiDialog(true)}
								/>
							) : null}
						{version ? <p className="name" style={{ marginLeft: 'auto' }}>ver {version}.0</p> : null}
						{headerState?.metadata ? <Button icon="fa-solid fa-pen-to-square" label="Metadata" onClick={() => navigate('/MyDataPools/EditData/Metadata', { state: { datapool, version } })} /> : null}
						{headerState?.publish && !headerState.metadata ? <Button icon="fa-solid fa-lock-open" label="Publish" onClick={() => navigate('/MyDataPools/EditData/Metadata', { state: { datapool, version } })} /> : null}
						{headerState?.publish && headerState.metadata ? <Button icon="fa-solid fa-lock-open" label="Publish" onClick={handleUnlock} loading={lockLoading} /> : null}
						{headerState?.lock ? <Button icon="fa-solid fa-lock" severity="danger" label="Lock" onClick={handleLock} loading={lockLoading} /> : null}
					</div>
				</div>
			</div>
			<NameDialog datapool={datapool} dialogVisibility={dialogVisibility} setDialogVisibility={setDialogVisibility} />
		</>
	);
};

export default DatapoolHeader;
