import React, { useState } from 'react';
import { InputText } from 'primereact/inputtext';

const DescriptionBox = ({ body, files, setFiles }) => {
	const [description, setDescription] = useState(body.description);

	return (
		<InputText
			value={description}
			onChange={(e) => {
				setDescription(e.target.value);
				const newFiles = [...files];
				const index = newFiles.findIndex((file) => file.key === body.key);
				const file = { ...newFiles[index] };
				file.description = e.target.value;
				newFiles[index] = file;
				setFiles(newFiles);
			}}
		/>

	);
};

export default DescriptionBox;
