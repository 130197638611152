import React, { useMemo, useState } from 'react';
import { Dropdown } from 'primereact/dropdown'; 

const TypeSelector = ({ body, files, setFiles }) => {
	const [selectedType, setSelectedType] = useState(body.document_type);

	const options = useMemo(() => {
		let pub = false;
		files.forEach((file) => {
			if (file.document_type === 'Protocol') {
				pub = true;
			}
		});
		if (!pub || selectedType === 'Protocol') {
			return ['Publication', 'Protocol', 'Other'];
		}
		return ['Publication', 'Other'];
	}, [files]);

	return (
		<Dropdown
			value={selectedType}
			onChange={(e) => {
				setSelectedType(e.value);
				const newFiles = [...files];
				const index = newFiles.findIndex((file) => file.key === body.key);
				const file = { ...newFiles[index] };
				file.document_type = e.value;
				newFiles[index] = file;
				setFiles(newFiles);
			}}
			options={options}
		/>
	);
};

export default TypeSelector;
